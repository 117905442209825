.app-monospaced {
  font-family: monospace;
}

.app-align-center {
    text-align: center;
 }

.app-map {
    height: 200px;
}
